import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Nếu đã đăng nhập, điều hướng tới trang home
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://tuehaiduong.com.vn/api/login', {
        username: username.trim().toLowerCase(),
        password,
      });

      if (response.status === 200) {
        // Lưu token và username
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username);
        // Điều hướng tới trang Home
        navigate('/home');
      }
    } catch (error) {
      // Hiển thị thông báo lỗi từ server hoặc lỗi kết nối
      const errorMessage = error.response?.data?.error || 'Có lỗi xảy ra, vui lòng thử lại';
      alert(errorMessage);
    }
  };

  return (
    <div className="login-page">
      <div className="login-card">
        <h2 className="text-center">Đăng Nhập</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3">
            <label htmlFor="username">Tên đăng nhập</label>
            <input
              type="text"
              id="username"
              className="form-control"
              placeholder="Nhập tên đăng nhập"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Nhập mật khẩu"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Đăng nhập
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

